import notify from '@/shared/toaster/lib/notify';

import i18n from '../i18n';
import { SerializedError } from '@reduxjs/toolkit';

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export interface CustomError {
  data: {
    error: {
      code: number;
      msg: string;
    };
  };
}

export interface DefaultResponse<T = undefined> {
  data: T extends undefined ? never : T;
  success: boolean;
}

export type PaginationParams<T extends object = {}> = Partial<
  {
    page: number;
    pageSize: number;
  } & T
>;

export type DefaultResponseWithPagination<T = undefined, O extends object = {}> = {
  data: T extends undefined ? never : T;
  success: boolean;
  totalPages: number;
} & O;

export interface BaseModalRef {
  open: () => void;
  close: () => void;
}

export const getUserLang = () => {
  // return localStorage.getItem('lang') || navigator.language.split('-')[0] || 'uz';
  return localStorage.getItem('lang') || 'uz';
};

export const sleep = (ms: number, reject?: boolean): Promise<void> => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      if (reject) rej();
      res();
    }, ms);
  });
};

export const getFirstLetters = (str: string) => {
  if (!str) return [];

  return str
    .split(' ')
    .slice(0, 2)
    .map((el) => el[0]);
};

export const getFetchEror = (error: CustomError | SerializedError) => {
  if ('status' in error && error.status === 'PARSING_ERROR') {
    return '';
  }
  if ('data' in error) {
    return error?.data?.error?.msg;
  }

  return '';
};

export const handleError = (error: CustomError | SerializedError) => {
  let res = '';
  res = getFetchEror(error as CustomError);
  notify(res, 'error');
  return res;
};

export const blobToBase64 = (blob: Blob) => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      res(reader.result);
    };
    reader.onerror = () => {
      rej();
    };
  });
};

export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  return new Blob([arrayBuffer], { type: mimeString });
};

export const getSumFromTiins = (sum: number = 0, isTiins = true) => {
  return sum / (isTiins ? 1 : 100);
};

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      res(reader.result as string);
    };
    reader.onerror = () => {
      rej();
    };
  });
};

export const getDaysString = (days: number): string => {
  const lastDigit = days % 10;
  const lastTwoDigits = days % 100;

  if (lastDigit === 1 && lastTwoDigits !== 11) {
    return 'день';
  } else if (
    (lastDigit === 2 || lastDigit === 3 || lastDigit === 4) &&
    (lastTwoDigits < 10 || lastTwoDigits >= 20)
  ) {
    return 'дня';
  } else {
    return 'дней';
  }
};

export const parseStringBoolean = (str: string | boolean) => {
  if (str === 'true') {
    return true;
  }

  if (str === 'false') {
    return false;
  }

  return str;
};

export const getAndConvertBlobToFile = async (
  response: Response,
  fileName: string,
  extension: string
) => {
  if (response.status === 200) {
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const tagA = document.createElement('a');
    tagA.download = `${fileName}.${extension}`;
    tagA.href = url;
    tagA.click();
    tagA.remove();
    URL.revokeObjectURL(url);
  } else notify(i18n.t('failed_to_download_file'), 'error');
};

export const checkIfHasPermission = (
  permissionsToObserve: string[] | undefined,
  permissions: string[]
) => {
  if (
    permissionsToObserve &&
    (!permissionsToObserve.length || permissionsToObserve.includes('187'))
  ) {
    return true;
  }
  return permissions.some((permission) => permissionsToObserve?.includes(permission));
};

export const getDefaultLink = (roles: string[]) => {
  const roleLinks: Record<string, string> = {
    '18711': '/balance',
    '18712': '/balance',
    '18721': '/employees',
    '18722': '/employees',
    '18723': '/employees',
    '18731': '/settlements',
    '18732': '/settlements',
    // '18741': '/customer/document',
    // '18742': '/customer/document',
    '18741': '/yandex/documents',
    '18742': '/yandex/documents',
  };

  const employeesRoles = ['18721', '18722', '18723'];
  const defaultLink = '/employees';

  for (const role of roles) {
    if (employeesRoles.includes(role)) {
      return roleLinks[role];
    }
  }

  for (const role of roles) {
    if (roleLinks[role]) {
      return roleLinks[role];
    }
  }

  return defaultLink;
};
