import { FC, useCallback, useMemo, useState } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { EmptyPlaceholder } from '../../../shared/empty-placeholder';
import { UITable } from '../../../shared/ui/ui-table';
import { UIActionButton } from '../../../shared/ui/ui-action-button';
import { Link } from 'react-router-dom';
import { numericFormatter } from 'react-number-format';
import { FieldArrayWithId } from 'react-hook-form';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import { ReportsPageFormValues } from '../types';
import { useReportsContext } from '../context';

interface UIYandexTableProps {
  yandexReportRows: FieldArrayWithId<ReportsPageFormValues, 'yandexReportRows', 'id'>[] | undefined;
  onClickOpenChangeRowModal: (index: number) => void;
  counterFilter: number;
}

export const UIYandexTable: FC<UIYandexTableProps> = ({
  yandexReportRows = [],
  onClickOpenChangeRowModal,
  counterFilter,
}) => {
  const { t } = useTranslation();
  const {
    StackComponent,
    isRewardReport,
    isPosted,
    yandexReportData,
    isTravelReport,
    unique,
    onClickRemoveYandexOrUklonRow,
    onMountGetReport,
  } = useReportsContext();
  const [sliceIndex, setSliceIndex] = useState<number>(1);
  const isFromApi = yandexReportData?.fillType === 'fromApi';

  const { currentPage, isPaginationDisabled } = useMemo(() => {
    let page = 1;
    const isPaginationDisabled =
      yandexReportRows.length < 11 &&
      (typeof yandexReportData?.totalPages === 'undefined' || yandexReportData?.totalPages === 1);

    if (yandexReportRows.length > 10) {
      sliceIndex === 1 ? (page = 1) : (page = sliceIndex - 1);

      return { currentPage: page, isPaginationDisabled };
    }

    if (yandexReportData?.totalPages === 1) page = 1;
    else if (yandexReportData?.totalPages) page = yandexReportData.totalPages;

    if (
      yandexReportData &&
      yandexReportData.totalPages !== 1 &&
      typeof yandexReportData.nextPage === 'number'
    ) {
      page = yandexReportData.nextPage - 1;
    }

    return { currentPage: page, isPaginationDisabled };
  }, [yandexReportData, yandexReportRows, sliceIndex]);

  const rowIndex = useMemo(() => {
    if (yandexReportRows.length > 10) {
      return sliceIndex;
    } else return currentPage;
  }, [yandexReportRows, sliceIndex, currentPage]);

  const calculateDriversProfit = useCallback(
    (row: FieldArrayWithId<ReportsPageFormValues, 'yandexReportRows', 'id'>) => {
      const amount = row.amount;
      const aggregatorCommission = Number(row.aggregatorCommission);
      const taxiCommission = Number(row.taxiCommission);
      let driversProfit = amount;

      if (aggregatorCommission && taxiCommission && amount) {
        driversProfit = +(amount - aggregatorCommission - taxiCommission).toFixed(2);
      }

      return driversProfit;
    },
    []
  );

  return (
    <UITable
      data={
        yandexReportRows.length > 10
          ? yandexReportRows.slice(
              sliceIndex === 1 ? 0 : (sliceIndex - 1) * 10,
              sliceIndex === 1 ? 10 : sliceIndex * 10
            )
          : yandexReportRows
      }
      EmptyPlaceholder={<EmptyPlaceholder />}
      columns={[
        {
          label: '№',
          render: (_, index) => (rowIndex === 1 ? index + 1 : (rowIndex - 1) * 10 + index + 1),
          nowrap: true,
        },
        {
          label: t('customer_trips_page.employee'),
          render: ({ name, pinfl }) => (
            <Stack spacing={1.5} width={350}>
              <Typography variant='body2'>{name}</Typography>
              <Typography variant='body2'>{pinfl}</Typography>
            </Stack>
          ),
        },
        isTravelReport
          ? {
              label: t('customer_trips_page.contract_number'),
              key: 'contractNumber',
            }
          : null,
        isTravelReport
          ? {
              label: t('customer_trips_page.contract_date'),
              render: ({ contractDate }) => {
                const dateToShow = dayjs(contractDate).format('DD.MM.YYYY');
                return dateToShow;
              },
            }
          : null,
        {
          label: t('customer_trips_page.in_system'),
          // key: 'active',
          render: ({ active }) =>
            active ? (
              <Typography variant='body2' color='gray'>
                {t('exist')}
              </Typography>
            ) : (
              <Typography variant='body2' color='red'>
                {t('no')}
              </Typography>
            ),
        },
        !isRewardReport
          ? {
              label: t('customer_trips_page.total_sum'),
              key: 'amount',
              render: ({ amount }) =>
                amount
                  ? numericFormatter(Number(amount).toFixed(2), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : 0,
            }
          : null,
        !isRewardReport
          ? {
              label: t('customer_trips_page.aggregator_fee'),
              key: 'aggregatorCommission',
              render: ({ aggregatorCommission }) =>
                aggregatorCommission
                  ? numericFormatter(Number(aggregatorCommission).toFixed(2), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : 0,
            }
          : null,
        !isRewardReport
          ? {
              label: t('customer_trips_page.taxi_fleet_fee'),
              key: 'taxiCommission',
              render: ({ taxiCommission }) =>
                taxiCommission
                  ? numericFormatter(Number(taxiCommission).toFixed(2), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : 0,
            }
          : null,
        !isRewardReport
          ? {
              label: t('customer_trips_page.drivers_sum'),
              render: (row) => {
                const driversProfit = calculateDriversProfit(row);
                return driversProfit
                  ? numericFormatter(Number(driversProfit).toFixed(2), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : 0;
              },
            }
          : null,
        isRewardReport
          ? {
              label: t('customer_trips_page.drivers_bonuses'),
              // key: 'taxiDriverReward',
              render: ({ taxiDriverReward }) =>
                taxiDriverReward
                  ? numericFormatter(Number(taxiDriverReward).toFixed(2), {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    })
                  : 0,
            }
          : null,
        isTravelReport && isPosted
          ? {
              label: t('yandex_docs_page.documents'),
              render: ({ documents }) => {
                if (!documents?.length) return null;

                return (
                  <Stack spacing={1}>
                    {documents
                      .filter((doc) => doc.didoxId)
                      .map((doc, index) => (
                        <Link
                          key={index}
                          to={
                            doc.didoxId
                              ? `/customer/documents/${doc.didoxId}/report`
                              : `/customer/reports/${unique}`
                          }
                        >
                          {doc.type}
                        </Link>
                      ))}
                  </Stack>
                );
              },
            }
          : null,
        !isFromApi
          ? {
              render: ({ unique }, index) => (
                <StackComponent justifyContent='flex-end' spacing={1.5}>
                  <Tooltip title={t('edit')}>
                    <Box
                      component='span'
                      onClick={!isPosted ? () => onClickOpenChangeRowModal(index) : undefined}
                    >
                      <UIActionButton icon='edit' disabled={isPosted || !isFromApi} />
                    </Box>
                  </Tooltip>
                  <Tooltip title={t('delete')}>
                    <Box
                      component='span'
                      onClick={
                        !isPosted
                          ? () => onClickRemoveYandexOrUklonRow(index, unique ? unique : undefined)
                          : undefined
                      }
                    >
                      <UIActionButton icon='delete' disabled={isPosted} />
                    </Box>
                  </Tooltip>
                </StackComponent>
              ),
            }
          : null,
      ]}
      PaginationProps={{
        page: yandexReportRows.length > 10 ? sliceIndex : currentPage,
        count:
          yandexReportRows.length > 10
            ? Math.ceil(yandexReportRows.length / 10)
            : yandexReportData?.totalPages,
        disabled: isPaginationDisabled,
        onChange: (_, page) => {
          const params: { page: number; active?: boolean } = { page };

          if (counterFilter === 1) {
            params.active = true;
          } else if (counterFilter === 2) {
            params.active = false;
          }

          yandexReportRows.length < 11 ? onMountGetReport(params) : setSliceIndex(page);
        },
      }}
    />
  );
};
