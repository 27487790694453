import { Colors } from '@/app/constants/colors';
import { Typography } from '@mui/material';

interface UICounterBadgeProps {
  count?: number;
  isActive: boolean;
}

export const UICounterBadge: React.FC<UICounterBadgeProps> = ({ count = 0, isActive }) => {
  return (
    <Typography
      display='flex'
      justifyContent='center'
      alignItems='center'
      variant='body2'
      sx={{
        width: `${Math.max(35, 20 + count.toString().length * 8)}px`,
        borderRadius: '20px',
        marginLeft: '8px',
        color: isActive ? Colors.WHITE_TEXT : Colors.SECONDARY,
        backgroundColor: isActive ? Colors.ACCENT : Colors.LGRAY,
        transition: 'color 0.3s ease, background-color 0.3s ease',
      }}
    >
      {count}
    </Typography>
  );
};
