import { FC, useMemo } from 'react';
import { useAppSelector, useFilters } from '@/app/hooks';
import { useModalRef } from '@/app/hooks/use-modal-ref';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { PageSizeSelect } from '@/shared/page-size-select';
import { UIButton } from '@/shared/ui/ui-button';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerDocsFilterComponent } from './yandex-docs-filter';
import { CreateReportModal } from './create-report-modal';
import { YandexReportsTable } from './yandex-reports-table';
import { UklonReportsTable } from './uklon-reports-table';
import { useGetReportsQuery, useGetReportsUklonQuery } from '@/app/api';
import PermissionCheckHOC from '../../../shared/permission-hoc';
import { checkIfHasPermission } from '../../../app/helpers';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import { FilterParams, ReportsTabComponentProps, YandexDocsFormValues } from '../types';
import { DocumentReportStatusId } from '@/app/api/model';
import { UIChipColor } from '@/shared/ui/ui-chip/types';

const initialFilterParams: FilterParams = {
  page: 1,
  pageSize: 10,
  dateFrom: '',
  dateTo: '',
  type: undefined,
  status: undefined,
  search: '',
};

export const ReportsTabComponent: FC<ReportsTabComponentProps> = ({
  typesData,
  statusesData,
  isFetchingReportTypes = false,
}) => {
  const { t } = useTranslation();
  const { company, roles } = useAppSelector((state) => state.auth);
  const { isUklon, isYandex } = useMemo(() => {
    const isUklon = company?.invitationFlow === 'uklon';
    const isYandex = company?.invitationFlow === 'yandex';

    return { isUklon, isYandex };
  }, [company]);

  const form = useForm<YandexDocsFormValues>({
    defaultValues: {
      ...initialFilterParams,
      type: { id: undefined, name: '' },
      status: { id: undefined, name: '' },
    },
  });

  const { filterParams, onChangeFilterParams } = useFilters<FilterParams>({
    initialFilterParams,
  });

  const yandexDocsFilterRef = useModalRef();
  const createReportModalRef = useModalRef();

  const { data: yandexReportsData, isFetching: isFetchingYandexReports } = useGetReportsQuery(
    filterParams,
    { skip: !isYandex }
  );
  const { data: uklonReportsData, isFetching: isFetchingUklonReports } = useGetReportsUklonQuery(
    filterParams,
    { skip: !isUklon }
  );

  const onSubmitHandler = (data: YandexDocsFormValues) => {
    onChangeFilterParams({
      ...data,
      type: typeof data?.type === 'object' ? data.type.id : data?.type,
      status: typeof data?.status === 'object' ? data.status.id : data?.status,
      dateFrom:
        typeof data.dateFrom === 'string'
          ? data.dateFrom
          : data.dateFrom
            ? dayjs(data.dateFrom).format('DD.MM.YYYY')
            : '',
      dateTo:
        typeof data.dateTo === 'string'
          ? data.dateTo
          : data.dateTo
            ? dayjs(data.dateTo).format('DD.MM.YYYY')
            : '',
      page: 1,
    });
  };

  const getChipType = (
    status: DocumentReportStatusId,
    label: string
  ): {
    label: string;
    color: UIChipColor;
  } => {
    switch (status) {
      case 'canceled':
        return {
          label: label,
          color: 'error',
        };
      case 'draft':
        return {
          label: label,
          color: 'purple',
        };
      case 'posted':
        return {
          label: label,
          color: 'success',
        };
      default:
        return {
          label: label,
          color: 'purple',
        };
    }
  };

  const renderUITableComponent = () => {
    switch (company?.invitationFlow) {
      case 'yandex':
        return (
          <YandexReportsTable
            isFetchingYandexReports={isFetchingYandexReports}
            yandexReportsData={yandexReportsData}
            statusesData={statusesData}
            filterParams={filterParams}
            onChangeFilterParams={onChangeFilterParams}
            getChipType={getChipType}
          />
        );
      case 'uklon':
        return (
          <UklonReportsTable
            isFetchingUklonReports={isFetchingUklonReports}
            uklonReportsData={uklonReportsData}
            statusesData={statusesData}
            filterParams={filterParams}
            onChangeFilterParams={onChangeFilterParams}
            getChipType={getChipType}
          />
        );
    }
  };

  const openCreateReportModal = () => createReportModalRef.current?.open();

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18742']), [roles]);

  return (
    <Stack spacing={2}>
      <Stack direction='row' justifyContent='space-between'>
        <PermissionCheckHOC
          WrappedComponent={UIButton}
          props={{
            onClick: openCreateReportModal,
            children: t('yandex_docs_page.form_report'),
            disabled: !hasPermission,
          }}
        />

        <Stack
          component='form'
          direction='row'
          spacing={1.5}
          onSubmit={form.handleSubmit(onSubmitHandler)}
        >
          <FormTextInput control={form.control} name='search' label={t('search')} />
          <UIButton type='submit'>{t('to_find')}</UIButton>
          <PageSizeSelect
            value={filterParams.pageSize}
            onChange={({ target }) =>
              onChangeFilterParams({ pageSize: Number(target.value), page: 1 })
            }
          />
          <UIButton
            color='secondary'
            variant='outlined'
            onClick={() => {
              yandexDocsFilterRef.current?.open();
            }}
          >
            {t('filter')}
          </UIButton>
        </Stack>
      </Stack>

      {renderUITableComponent()}

      <CustomerDocsFilterComponent
        ref={yandexDocsFilterRef}
        form={form}
        onChangeFilterParams={onChangeFilterParams}
        initialFilterParams={initialFilterParams}
        typesData={typesData}
        statusesData={statusesData}
      />
      <CreateReportModal
        ref={createReportModalRef}
        typesData={typesData}
        isFetching={isFetchingReportTypes}
      />
    </Stack>
  );
};
