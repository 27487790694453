import { FC } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { UIFormLabel } from '../../../shared/ui/ui-form-label';
import { Control, Controller } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { DocumentReportFillType, DocumentReportTypeId, GetDocumentReportTypes } from '../../../app/api/model';
import FormAutocompleteInput from '@/shared/inputs/form-autocomplete-input';

interface CreateYandexReportComponentProps {
  control: Control<{ type?: DocumentReportTypeId, fillType?: DocumentReportFillType }, any>;
  typesData: GetDocumentReportTypes[] | undefined;
}

export const CreateYandexReportComponent: FC<CreateYandexReportComponentProps> = ({
  control,
  typesData,
}) => {
  const { t } = useTranslation();

  const fillType = {
    'fromApi': t('fromApi'),
    'manual': t('manual'),
  }
  return (
    <>
      <FormControl>
        <UIFormLabel>{t('customer_trips_page.report_type')}:</UIFormLabel>

        <Controller
          control={control}
          name='type'
          render={({ field }) => (
            <RadioGroup {...field}>
              {typesData?.map((obj) => (
                <FormControlLabel
                  key={obj.id}
                  value={obj.id}
                  label={obj.name}
                  required
                  control={<Radio />}
                />
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>

      <FormControl sx={{ mt: 2 }} required>
        <UIFormLabel>{t('method_of_filling_title')}:</UIFormLabel>

        <FormAutocompleteInput
          control={control}
          name="fillType"
          options={Object.keys(fillType)}
          getOptionLabel={(option) => fillType[option as keyof typeof fillType]}
          isOptionEqualToValue={(option, value) => option === value}
          TextFieldProps={{
            placeholder: t('empty_placeholder'),
          }}
          rules={{
            required: true,
          }}
        />

      </FormControl>
    </>
  );
};
