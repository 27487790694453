import { memo } from 'react';
import { UISection } from '@/shared/ui/ui-section';
import { Stack, Typography } from '@mui/material';
import { UIButton } from '@/shared/ui/ui-button';
import { useReportsContext } from '../context';
import { numericFormatter } from 'react-number-format';
import { useWatch } from 'react-hook-form';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';

export const BottomSectionComponent = memo(
  () => {
    const { t } = useTranslation();
    const {
      StackComponent,
      form,
      isRewardReport,
      isPosted,
      isDeclineReportDisabled,
      isReportEditLoading,
      isReportPostLoading,
      onClickPostReport,
      yandexReportData,
      importedCounts,
    } = useReportsContext();

    const yandexReportRows = useWatch({ control: form.control, name: 'yandexReportRows' });
    const uklonReportRows = useWatch({ control: form.control, name: 'uklonReportRows' });

    const totalSums =
      yandexReportData?.totalAmount ||
      yandexReportRows
        ?.reduce((acc, row) => {
          if (row.amount) {
            acc += +row.amount;
          }

          return acc;
        }, 0)
        .toFixed(2);

    const totalAggregatorFees =
      !isRewardReport && yandexReportRows
        ? yandexReportData?.totalAggregatorCommission ||
          yandexReportRows
            .reduce((acc, row) => {
              if (row.aggregatorCommission && row.amount) {
                acc += +row.aggregatorCommission;
              }

              return acc;
            }, 0)
            .toFixed(2)
        : 0;

    const totalTaxiFees =
      !isRewardReport && yandexReportRows
        ? yandexReportData?.totalTaxiCommission ||
          yandexReportRows
            .reduce((acc, row) => {
              if (row.taxiCommission && row.amount) {
                acc += +row.taxiCommission;
              }

              return acc;
            }, 0)
            .toFixed(2)
        : 0;

    const driversProfit =
      !isRewardReport && yandexReportRows
        ? (yandexReportData?.totalAmount ?? 0) -
            ((yandexReportData?.totalAggregatorCommission ?? 0) +
              (yandexReportData?.totalTaxiCommission ?? 0)) ||
          yandexReportRows
            .reduce((acc, row) => {
              if (row.aggregatorCommission && row.taxiCommission && row.amount) {
                const aggregatorFee = +row.aggregatorCommission;
                const taxiFleetFee = +row.taxiCommission;

                acc += row.amount - aggregatorFee - taxiFleetFee;
              }

              return acc;
            }, 0)
            .toFixed(2)
        : 0;

    const driversBonuses =
      isRewardReport && yandexReportRows
        ? yandexReportData?.totalAmount ||
          yandexReportRows
            .reduce((acc, row) => {
              if (row.taxiDriverReward) acc += +row.taxiDriverReward;

              return acc;
            }, 0)
            .toFixed(2)
        : 0;

    const activeDrivers = uklonReportRows
      ? uklonReportRows.reduce((acc, row) => (row.active ? (acc += 1) : acc), 0)
      : 0;

    const tripsSum = uklonReportRows
      ? uklonReportRows.reduce((acc, row) => (acc += row.tripCostCash + row.tripCostNonCash), 0)
      : 0;

    const uklonComission = uklonReportRows
      ? uklonReportRows.reduce(
          (acc, row) => (acc += row.uklonCommissionCash + row.uklonCommissionNonCash),
          0
        )
      : 0;

    const driverNetIncomeFromTrips = tripsSum - Math.abs(uklonComission);

    return (
      <UISection>
        <Stack direction='column' spacing={3}>
          <Typography variant='h5'>{t('customer_trips_page.total')}</Typography>
          <StackComponent
            justifyContent='space-between'
            sx={{ backgroundColor: Colors.LGRAY, p: 3, borderRadius: 1 }}
          >
            {!!yandexReportRows?.length && !isRewardReport && (
              <>
                <Stack>
                  <Typography variant='body2'>{t('customer_trips_page.total_sum')}</Typography>
                  <Typography variant='body1'>
                    {totalSums
                      ? numericFormatter(String(totalSums), {
                          thousandSeparator: ' ',
                          decimalScale: 2,
                        })
                      : 0}{' '}
                    {t('currency_uzs')}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant='body2'>{t('customer_trips_page.aggregator_fee')}</Typography>
                  <Typography variant='body1'>
                    {totalAggregatorFees
                      ? numericFormatter(String(totalAggregatorFees), {
                          thousandSeparator: ' ',
                          decimalScale: 2,
                        })
                      : 0}{' '}
                    {t('currency_uzs')}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography variant='body2'>{t('customer_trips_page.taxi_fleet_fee')}</Typography>
                  <Typography variant='body1'>
                    {totalTaxiFees
                      ? numericFormatter(String(totalTaxiFees), {
                          thousandSeparator: ' ',
                          decimalScale: 2,
                        })
                      : 0}{' '}
                    {t('currency_uzs')}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography variant='body2'>{t('customer_trips_page.dirvers_profit')}</Typography>
                  <Typography variant='body1'>
                    {driversProfit
                      ? numericFormatter(String(driversProfit), {
                          thousandSeparator: ' ',
                          decimalScale: 2,
                        })
                      : 0}{' '}
                    {t('currency_uzs')}
                  </Typography>
                </Stack>
              </>
            )}

            {!!yandexReportRows?.length && isRewardReport && (
              <Stack>
                <Typography variant='body2'>{t('customer_trips_page.drivers_bonuses')}</Typography>
                <Typography variant='body1'>
                  {driversBonuses
                    ? numericFormatter(String(driversBonuses), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : 0}{' '}
                  {t('currency_uzs')}
                </Typography>
              </Stack>
            )}

            {!!uklonReportRows?.length && (
              <>
                <Stack>
                  <Typography variant='body2'>{t('uklon_trips_page.active_drivers')}</Typography>
                  <Typography variant='body1'>
                    {activeDrivers
                      ? numericFormatter(String(activeDrivers), {
                          thousandSeparator: ' ',
                          decimalScale: 0,
                        })
                      : 0}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography variant='body2'>{t('uklon_trips_page.trips_sum')}</Typography>
                  <Typography variant='body1'>
                    {tripsSum
                      ? numericFormatter(String(tripsSum), {
                          thousandSeparator: ' ',
                          decimalScale: 2,
                        })
                      : 0}{' '}
                    {t('currency_uzs')}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography variant='body2'>{t('uklon_trips_page.uklon_comission')}</Typography>
                  <Typography variant='body1'>
                    {uklonComission
                      ? numericFormatter(String(uklonComission), {
                          thousandSeparator: ' ',
                          decimalScale: 2,
                        })
                      : 0}{' '}
                    {t('currency_uzs')}
                  </Typography>
                </Stack>

                <Stack>
                  <Typography variant='body2'>{t('customer_trips_page.drivers_sum')}</Typography>
                  <Typography variant='body1'>
                    {driverNetIncomeFromTrips
                      ? numericFormatter(String(driverNetIncomeFromTrips), {
                          thousandSeparator: ' ',
                          decimalScale: 2,
                        })
                      : 0}{' '}
                    {t('currency_uzs')}
                  </Typography>
                </Stack>
              </>
            )}
          </StackComponent>

          <StackComponent justifyContent='space-between'>
            <UIButton
              disabled={isPosted || isReportPostLoading}
              loading={isReportEditLoading}
              type='submit'
            >
              {t('save')}
            </UIButton>

            <StackComponent spacing={1.5}>
              <UIButton
                disabled={isPosted || isReportEditLoading || !!importedCounts?.nonActiveCount}
                loading={isReportPostLoading}
                onClick={() => onClickPostReport(true, true)}
              >
                {t('customer_trips_page.run_report')}
              </UIButton>
              <UIButton
                color='inherit'
                disabled={isDeclineReportDisabled}
                onClick={() => onClickPostReport(false)}
              >
                {t('customer_trips_page.cancel_running')}
              </UIButton>
            </StackComponent>
          </StackComponent>
        </Stack>
      </UISection>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);
