import { memo, SyntheticEvent, useRef, useState } from 'react';
import { UISection } from '@/shared/ui/ui-section';
import { Stack } from '@mui/material';
import UITabs from '../../../shared/ui/ui-tabs';
import { UIButton } from '@/shared/ui/ui-button';
import { ImportRowsModal } from './import-rows-modal';
import { UIYandexTable } from './ui-yandex-table';
import { UIUklonTable } from './ui-uklon-table';
import { useReportsContext } from '../context';
import { ChangeRowModal } from './change-row-modal';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { ChangeRowModalRef } from '../types';
import { BaseModalRef } from '@/app/helpers';
import { UICounterBadge } from '@/shared/ui/ui-counter-badge';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';

export const MiddleSectionComponent = memo(
  () => {
    const { t } = useTranslation();
    const {
      StackComponent,
      isRewardReport,
      isPosted,
      isTravelReport,
      isYandex,
      isUklon,
      yandexReportRows = [],
      uklonReportRows = [],
      onClickRemoveYandexOrUklonRow,
      onMountGetReport,
      yandexReportData,
      uklonReportData,
      isImportFromAggregatorLoading,
      onClickImportRowsFromAggregator,
      isImportInProgress,
      importedCounts,
    } = useReportsContext();

    const importRowsModalRef = useRef<BaseModalRef>(null);
    const changeRowModalRef = useRef<ChangeRowModalRef>(null);
    // const createClosingDocumentModalRef = useRef<CreateClosingDocumentModalRef>(null);
    const [counterFilter, setCounterFilter] = useState<number>(0);

    const isFromApi = yandexReportData?.fillType === 'fromApi';
    const onClickOpenModal = () => importRowsModalRef.current?.open();

    const onClickOpenChangeRowModal = (index: number) => {
      let row;
      let titleToSet = t('corporal_trips');
      if (isRewardReport) titleToSet = t('reward_trips');
      if (isTravelReport) titleToSet = t('common_trips');
      if (isUklon) titleToSet = t('commissioner_report') + ' Uklon';
      if (isYandex) row = yandexReportRows[index];
      if (isUklon) row = uklonReportRows[index];
      changeRowModalRef.current?.init({
        index,
        titleToSet,
        row,
      });
    };

    const onChangeTabHandle = (_event: SyntheticEvent<Element, Event>, value: number) => {
      setCounterFilter(value);
      switch (value) {
        case 1:
          if (yandexReportData?.history.edited) {
            onMountGetReport({ page: 1, active: true });
          }
          break;
        case 2:
          if (yandexReportData?.history.edited) {
            onMountGetReport({ page: 1, active: false });
          }
          break;
        default:
          if (yandexReportData?.history.edited) {
            onMountGetReport({ page: 1 });
          }
      }
    };

    const renderUITable = () => {
      if (isYandex) {
        if (!yandexReportRows.length && !yandexReportData?.totalCount) return <EmptyPlaceholder />;
        return (
          <UITabs
            value={counterFilter}
            onChange={onChangeTabHandle}
            tabs={[
              {
                label: (
                  <Stack direction='row' alignItems='center'>
                    {t('employee_tabs.all')}

                    {!!importedCounts?.totalCount && (
                      <UICounterBadge
                        count={importedCounts?.totalCount}
                        isActive={counterFilter === 0}
                      />
                    )}
                  </Stack>
                ),
                component: (
                  <UIYandexTable
                    yandexReportRows={yandexReportRows}
                    onClickOpenChangeRowModal={onClickOpenChangeRowModal}
                    counterFilter={counterFilter}
                  />
                ),
              },
              {
                label: (
                  <Stack direction='row' alignItems='center'>
                    {t('employee_tabs.exist_in_system')}
                    {!!importedCounts.activeCount && (
                      <UICounterBadge
                        count={importedCounts?.activeCount}
                        isActive={counterFilter === 1}
                      />
                    )}
                  </Stack>
                ),
                component: (
                  <UIYandexTable
                    yandexReportRows={yandexReportRows.filter((row) => row.active)}
                    onClickOpenChangeRowModal={onClickOpenChangeRowModal}
                    counterFilter={counterFilter}
                  />
                ),
              },
              {
                label: (
                  <Stack direction='row' alignItems='center'>
                    {t('employee_tabs.not_in_system')}
                    {!!importedCounts.nonActiveCount && (
                      <UICounterBadge
                        count={importedCounts?.nonActiveCount}
                        isActive={counterFilter === 2}
                      />
                    )}
                  </Stack>
                ),
                component: (
                  <UIYandexTable
                    yandexReportRows={yandexReportRows.filter((row) => !row.active)}
                    onClickOpenChangeRowModal={onClickOpenChangeRowModal}
                    counterFilter={counterFilter}
                  />
                ),
              },
            ]}
          />
        );
      }

      if (isUklon) {
        return (
          <UITabs
            tabs={[
              {
                label: t('all'),
                component: (
                  <UIUklonTable
                    uklonReportData={uklonReportData}
                    uklonReportRows={uklonReportRows}
                    StackComponent={StackComponent}
                    isPosted={isPosted}
                    onClickOpenChangeRowModal={onClickOpenChangeRowModal}
                    onClickRemoveYandexOrUklonRow={onClickRemoveYandexOrUklonRow}
                    onMountGetReport={onMountGetReport}
                  />
                ),
              },
              {
                label: t('uklon_trips_page.active_employees'),
                component: (
                  <UIUklonTable
                    uklonReportData={uklonReportData}
                    uklonReportRows={uklonReportRows.filter((row) => row.active)}
                    StackComponent={StackComponent}
                    isPosted={isPosted}
                    onClickOpenChangeRowModal={onClickOpenChangeRowModal}
                    onClickRemoveYandexOrUklonRow={onClickRemoveYandexOrUklonRow}
                    onMountGetReport={onMountGetReport}
                  />
                ),
              },
              {
                label: t('uklon_trips_page.inactive_employees'),
                component: (
                  <UIUklonTable
                    uklonReportData={uklonReportData}
                    uklonReportRows={uklonReportRows.filter((row) => !row.active)}
                    StackComponent={StackComponent}
                    isPosted={isPosted}
                    onClickOpenChangeRowModal={onClickOpenChangeRowModal}
                    onClickRemoveYandexOrUklonRow={onClickRemoveYandexOrUklonRow}
                    onMountGetReport={onMountGetReport}
                  />
                ),
              },
            ]}
          />
        );
      }
    };

    return (
      <UISection>
        <Stack spacing={2}>
          {isYandex && (
            <StackComponent spacing={1.5}>
              {isFromApi && (
                <UIButton
                  disabled={isPosted || !!yandexReportRows?.length || isImportInProgress}
                  loading={isImportFromAggregatorLoading}
                  onClick={onClickImportRowsFromAggregator}
                >
                  {t('customer_trips_page.fill_in_by_aggregator_data')}
                </UIButton>
              )}
              {!isFromApi && (
                <UIButton
                  color='inherit'
                  onClick={onClickOpenModal}
                  disabled={isPosted || isImportFromAggregatorLoading}
                >
                  {t('customer_trips_page.download_from_file')}
                </UIButton>
              )}
            </StackComponent>
          )}
          {isUklon && (
            <StackComponent>
              <UIButton onClick={() => onMountGetReport()} color='inherit'>
                {t('uklon_trips_page.refresh_activity')}
              </UIButton>
            </StackComponent>
          )}

          {renderUITable()}

          <StackComponent justifyContent='flex-end'>
            <UIButton
              color='inherit'
              onClick={() => {
                if (isYandex && typeof yandexReportRows.length === 'number') {
                  onClickOpenChangeRowModal(yandexReportRows.length);
                }
                if (isUklon && typeof uklonReportRows.length === 'number') {
                  onClickOpenChangeRowModal(uklonReportRows.length);
                }
              }}
              disabled={isPosted || isFromApi}
            >
              {t('customer_trips_page.add_row')}
            </UIButton>
          </StackComponent>
        </Stack>
        <ImportRowsModal ref={importRowsModalRef} />
        <ChangeRowModal ref={changeRowModalRef} />
      </UISection>
    );
  },
  (prev, next) => _.isEqual(prev, next)
);
