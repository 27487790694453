import { FC, PropsWithChildren, useRef } from 'react';
import { Box, StackProps, Tooltip, Typography } from '@mui/material';
import { EmptyPlaceholder } from '../../../shared/empty-placeholder';
import { UITable } from '../../../shared/ui/ui-table';
import { UIActionButton } from '../../../shared/ui/ui-action-button';
import { FieldArrayWithId } from 'react-hook-form';
import { useLazyGetPdfUklonQuery } from '../../../app/api';
import { UklonFullInfoModal } from './uklon-full-info-modal';
import { numericFormatter } from 'react-number-format';

import TickCircle from '@/app/assets/icons/tick-circle.svg?react';
import CloseWarningIcon from '@/app/assets/icons/close-circle-warning.svg?react';

import { useTranslation } from 'react-i18next';
import { ReportsPageFormValues, UklonFullInfoModalRef } from '../types';
import { GetUklonReportResponseBody } from '../../../app/api/model';
import { Colors } from '../../../app/constants/colors';

interface UIUklonTableProps {
  uklonReportRows: FieldArrayWithId<ReportsPageFormValues, 'uklonReportRows', 'id'>[] | undefined;
  isPosted: boolean;
  uklonReportData: GetUklonReportResponseBody | undefined;
  onMountGetReport: (params?: { page?: number; active?: boolean }) => Promise<void>;
  onClickOpenChangeRowModal: (index: number) => void;
  onClickRemoveYandexOrUklonRow: (index: number, unique: number | undefined) => void;
  StackComponent: (props: PropsWithChildren<StackProps>) => JSX.Element;
}

export const UIUklonTable: FC<UIUklonTableProps> = ({
  uklonReportRows = [],
  uklonReportData,
  isPosted,
  StackComponent,
  onClickOpenChangeRowModal,
  onClickRemoveYandexOrUklonRow,
  onMountGetReport,
}) => {
  const { t } = useTranslation();

  const uklonFullInfoModalRef = useRef<UklonFullInfoModalRef>(null);

  const [getUklonPdf] = useLazyGetPdfUklonQuery();

  const onClickDownloadPdf = async (unique: number) => {
    const { success, base64 } = await getUklonPdf({ unique }).unwrap();
    if (success) {
      const tagA = document.createElement('a');
      tagA.download = `report-${unique}.pdf`;
      tagA.href = `data:text/plane;base64,${base64}`;
      tagA.click();
      tagA.remove();
    }
  };

  return (
    <>
      {' '}
      <UITable
        data={uklonReportRows}
        EmptyPlaceholder={<EmptyPlaceholder />}
        columns={[
          {
            label: '№',
            key: 'index',
          },
          {
            label: t('uklon_trips_page.activity'),
            render: ({ active }) => {
              const Icon = active ? TickCircle : CloseWarningIcon;
              return (
                <StackComponent alignItems='center'>
                  <Box
                    component={Icon}
                    width={20}
                    sx={{
                      '& path:first-of-type': {
                        fill: 'none',
                        stroke: active ? Colors.SUCCESS : Colors.BGRAY,
                      },
                      '& path:last-of-type': {
                        fill: active ? Colors.SUCCESS : Colors.BGRAY,
                      },
                    }}
                  />
                </StackComponent>
              );
            },
          },
          // {
          //   label: t('pinfl'),
          //   key: 'pinfl',
          // },
          // {
          //   label: t('fio'),
          //   key: 'name',
          // },
          {
            label: t('customer_trips_page.employee'),
            render: ({ name, pinfl }) => {
              return (
                <StackComponent direction='column'>
                  <Typography variant='body2'>{name}</Typography>
                  <Typography variant='body2' color={Colors.BGRAY}>
                    {pinfl}
                  </Typography>
                </StackComponent>
              );
            },
          },
          {
            label: t('uklon_trips_page.trips_sum'),
            render: ({ tripCostCash, tripCostNonCash }) =>
              +tripCostCash + +tripCostNonCash
                ? numericFormatter(Number(+tripCostCash + +tripCostNonCash).toFixed(2), {
                    thousandSeparator: ' ',
                    decimalScale: 2,
                  })
                : 0,
          },
          {
            label: t('uklon_trips_page.uklon_comission'),
            render: ({ uklonCommissionCash, uklonCommissionNonCash }) =>
              +uklonCommissionCash + +uklonCommissionNonCash
                ? numericFormatter(
                    Number(+uklonCommissionCash + +uklonCommissionNonCash).toFixed(2),
                    {
                      thousandSeparator: ' ',
                      decimalScale: 2,
                    }
                  )
                : 0,
          },
          {
            label: t('customer_trips_page.drivers_sum'),
            render: ({
              tripCostCash,
              tripCostNonCash,
              uklonCommissionCash,
              uklonCommissionNonCash,
            }) => {
              const driverNetIncom =
                +tripCostCash +
                +tripCostNonCash -
                Math.abs(+uklonCommissionCash + +uklonCommissionNonCash);

              return (
                <Typography variant='body2' fontWeight='bold'>
                  {/* {driverNetIncom} */}
                  {driverNetIncom
                    ? numericFormatter(Number(driverNetIncom).toFixed(2), {
                        thousandSeparator: ' ',
                        decimalScale: 2,
                      })
                    : 0}
                </Typography>
              );
            },
          },
          // {
          //   label: t('uklon_trips_page.trip_cost_cash'),
          //   key: 'tripCostCash',
          // },
          // {
          //   label: t('uklon_trips_page.trip_cost_non_cash'),
          //   key: 'tripCostNonCash',
          // },
          // {
          //   label: t('uklon_trips_page.promo_code_cash'),
          //   key: 'promoCodeCash',
          // },
          // {
          //   label: t('uklon_trips_page.promo_code_non_cash'),
          //   key: 'promoCodeNonCash',
          // },
          // {
          //   label: t('uklon_trips_page.promo_code_non_cash'),
          //   key: 'promoCodeNonCash',
          // },
          // {
          //   label: t('uklon_trips_page.uklon_comission_cash'),
          //   key: 'uklonCommissionCash',
          // },
          // {
          //   label: t('uklon_trips_page.uklon_comission_non_cash'),
          //   key: 'uklonCommissionNonCash',
          // },
          // {
          //   label: t('uklon_trips_page.compensations'),
          //   key: 'compensations',
          // },
          // {
          //   label: t('uklon_trips_page.day_program_bonuses'),
          //   key: 'dayProgramBonuses',
          // },
          // {
          //   label: t('uklon_trips_page.order_bonuses'),
          //   key: 'orderBonuses',
          // },
          // {
          //   label: t('uklon_trips_page.tips_non_cash'),
          //   key: 'tipsNonCash',
          // },
          // {
          //   label: t('uklon_trips_page.branding_bonuses'),
          //   key: 'brandingBonuses',
          // },
          // {
          //   label: t('uklon_trips_page.fines'),
          //   key: 'fines',
          // },
          // {
          //   label: t('uklon_trips_page.wallet_replenishment_commission'),
          //   key: 'walletReplenishmentCommission',
          // },
          // {
          //   label: t('uklon_trips_page.wallet_transfer'),
          //   key: 'walletTransfer',
          // },
          // {
          //   label: t('uklon_trips_page.cash_payment'),
          //   key: 'cashPayment',
          // },
          // {
          //   label: t('uklon_trips_page.withdrawal_to_driver_card'),
          //   key: 'withdrawalToDriverCard',
          // },
          // {
          //   label: t('uklon_trips_page.withdrawal_commission_to_driver_card'),
          //   key: 'withdrawalCommissionToDriverCard',
          // },
          // {
          //   label: t('uklon_trips_page.driver_wallet_transfer'),
          //   key: 'driverWalletTransfer',
          // },
          // {
          //   label: t('uklon_trips_page.driver_wallet_replenishment'),
          //   key: 'driverWalletReplenishment',
          // },
          {
            render: ({ active, unique }, index) => {
              return (
                <StackComponent justifyContent='flex-end' spacing={1.5}>
                  <Tooltip title={t('inspect')}>
                    <Box component='span'>
                      <UIActionButton
                        icon='eye'
                        onClick={() => uklonFullInfoModalRef.current?.init(index)}
                      />
                    </Box>
                  </Tooltip>

                  {active && isPosted && (
                    <Tooltip title={t('upload')}>
                      <Box
                        component='span'
                        onClick={isPosted ? () => onClickDownloadPdf(unique) : undefined}
                      >
                        <UIActionButton icon='print' disabled={!isPosted} />
                      </Box>
                    </Tooltip>
                  )}

                  <Tooltip title={t('edit')}>
                    <Box
                      component='span'
                      onClick={!isPosted ? () => onClickOpenChangeRowModal(index) : undefined}
                    >
                      <UIActionButton icon='edit' disabled={isPosted} />
                    </Box>
                  </Tooltip>
                  <Tooltip title={t('delete')}>
                    <Box
                      component='span'
                      onClick={
                        !isPosted
                          ? () => onClickRemoveYandexOrUklonRow(index, unique ? unique : undefined)
                          : undefined
                      }
                    >
                      <UIActionButton icon='delete' disabled={isPosted} />
                    </Box>
                  </Tooltip>
                </StackComponent>
              );
            },
          },
        ]}
        PaginationProps={{
          page:
            (uklonReportData?.totalPages === 1
              ? 1
              : uklonReportData &&
                  uklonReportData.totalPages !== 1 &&
                  typeof uklonReportData.nextPage === 'number'
                ? uklonReportData.nextPage - 1
                : uklonReportData?.totalPages) ?? 1,
          count: uklonReportData?.totalPages,
          disabled:
            typeof uklonReportData?.totalPages === 'undefined' || uklonReportData.totalPages === 1,
          onChange: async (_, page) => await onMountGetReport({ page }),
        }}
      />
      <UklonFullInfoModal ref={uklonFullInfoModalRef} />
    </>
  );
};
