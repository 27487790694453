import { FC } from 'react';
import { FormControl, FormHelperText, Stack, TextField } from '@mui/material';
import { UIFormLabel } from '../../../shared/ui/ui-form-label';
import { useReportsContext } from '../context';
import { FormTextInput } from '../../../shared/inputs/form-text-input';
import { UseFormReturn } from 'react-hook-form';
import { DebouncedFunc } from 'lodash';

import { useTranslation } from 'react-i18next';
import { Colors } from '../../../app/constants/colors';
import { UklonReportRow } from '../../../app/api/model';

interface UklonRowModalBodyProps {
  uklonForm: UseFormReturn<UklonReportRow, any, undefined>;
  debounceOnChange: DebouncedFunc<(value: string | undefined) => Promise<void>>;
}

export const UklonRowModalBody: FC<UklonRowModalBodyProps> = ({ uklonForm, debounceOnChange }) => {
  const { t } = useTranslation();
  const { StackComponent } = useReportsContext();

  const { control, watch } = uklonForm;

  const fullName = watch('name') ?? '';
  const tripCostCash = watch('tripCostCash') ?? 0;
  const tripCostNonCash = watch('tripCostNonCash') ?? 0;
  const uklonCommissionCash = watch('uklonCommissionCash') ?? 0;
  const uklonCommissionNonCash = watch('uklonCommissionNonCash') ?? 0;
  const driverNetIncomeFromTrips = (
    +tripCostCash +
    +tripCostNonCash -
    (+uklonCommissionCash + +uklonCommissionNonCash)
  ).toFixed(2);
  const compensations = watch('compensations') ?? 0;
  const dayProgramBonuses = watch('dayProgramBonuses') ?? 0;
  const orderBonuses = watch('orderBonuses') ?? 0;
  const tipsNonCash = watch('tipsNonCash') ?? 0;
  const brandingBonuses = watch('brandingBonuses') ?? 0;
  const fines = watch('fines') ?? 0;
  const walletReplenishmentCommission = watch('walletReplenishmentCommission') ?? 0;
  const walletTransfer = watch('walletTransfer') ?? 0;
  const cashPayment = watch('cashPayment') ?? 0;
  const withdrawalToDriverCard = watch('withdrawalToDriverCard') ?? 0;
  const withdrawalCommissionToDriverCard = watch('withdrawalCommissionToDriverCard') ?? 0;
  const driverWalletTransfer = watch('driverWalletTransfer') ?? 0;
  const driverWalletReplenishment = watch('driverWalletReplenishment') ?? 0;

  return (
    <Stack spacing={2}>
      <FormControl required fullWidth>
        <UIFormLabel required>{t('pinfl')}</UIFormLabel>
        <FormTextInput
          control={control}
          name='pinfl'
          placeholder={t('pinfl')}
          onChange={async (event) => {
            const value = event.target.value;
            await debounceOnChange(value);
          }}
          regExp={/^\d+$/}
          rules={{
            required: true,
            validate: (val) => (typeof val === 'string' ? /^\d{14}$/.test(val) : false),
          }}
        />
      </FormControl>
      {fullName && <FormHelperText sx={{ color: Colors.INFO }}>{fullName}</FormHelperText>}

      <StackComponent alignItems='flex-end' spacing={1.5}>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.trip_cost_cash')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='tripCostCash'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.trip_cost_non_cash')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='tripCostNonCash'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
      </StackComponent>

      <StackComponent alignItems='flex-end' spacing={1.5}>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.promo_code_cash')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='promoCodeCash'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.promo_code_non_cash')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='promoCodeNonCash'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
      </StackComponent>

      <UIFormLabel>{t('uklon_trips_page.driver_income_from_trips')}</UIFormLabel>
      <TextField value={(+tripCostCash + +tripCostNonCash).toFixed(2)} disabled />

      <StackComponent alignItems='flex-end' spacing={1.5}>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.uklon_comission_cash')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='uklonCommissionCash'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.uklon_comission_non_cash')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='uklonCommissionNonCash'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
      </StackComponent>

      <UIFormLabel>{t('uklon_trips_page.driver_net_income_from_trips')}</UIFormLabel>
      <TextField value={driverNetIncomeFromTrips} disabled />

      <UIFormLabel>{t('uklon_trips_page.uklon_comission')}</UIFormLabel>
      <TextField value={(+uklonCommissionCash + +uklonCommissionNonCash).toFixed(2)} disabled />

      <UIFormLabel>{t('uklon_trips_page.driver_revenue_cash')}</UIFormLabel>
      <TextField value={(+tripCostCash - +uklonCommissionCash).toFixed(2)} disabled />

      <UIFormLabel>{t('uklon_trips_page.driver_revenue_non_cash')}</UIFormLabel>
      <TextField value={(+tripCostNonCash - +uklonCommissionNonCash).toFixed(2)} disabled />

      <FormControl fullWidth>
        <UIFormLabel>{t('uklon_trips_page.compensations')}</UIFormLabel>
        <FormTextInput
          control={control}
          name='compensations'
          regExp={/^(\d+)?(\.\d{0,2})?$/}
          rules={{
            validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
          }}
        />
      </FormControl>

      <StackComponent alignItems='flex-end' spacing={1.5}>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.day_program_bonuses')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='dayProgramBonuses'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.order_bonuses')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='orderBonuses'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
      </StackComponent>

      <StackComponent alignItems='flex-end' spacing={1.5}>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.tips_non_cash')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='tipsNonCash'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.branding_bonuses')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='brandingBonuses'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
      </StackComponent>

      <UIFormLabel>{t('uklon_trips_page.other_income')}</UIFormLabel>
      <TextField
        value={(
          +compensations +
          +dayProgramBonuses +
          +orderBonuses +
          +tipsNonCash +
          +brandingBonuses
        ).toFixed(2)}
        disabled
      />

      <StackComponent alignItems='flex-end' spacing={1.5}>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.fines')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='fines'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.wallet_replenishment_commission')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='walletReplenishmentCommission'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
      </StackComponent>

      <UIFormLabel>{t('uklon_trips_page.other_deductions')}</UIFormLabel>
      <TextField value={(+fines + +walletReplenishmentCommission).toFixed(2)} disabled />

      <UIFormLabel>{t('uklon_trips_page.driver_net_income')}</UIFormLabel>
      <TextField
        value={(+driverNetIncomeFromTrips - (+fines + +walletReplenishmentCommission)).toFixed(2)}
        disabled
      />

      <StackComponent alignItems='flex-end' spacing={1.5}>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.wallet_transfer')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='walletTransfer'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.cash_payment')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='cashPayment'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
      </StackComponent>

      <StackComponent alignItems='flex-end' spacing={1.5}>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.withdrawal_to_driver_card')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='withdrawalToDriverCard'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.withdrawal_commission_to_driver_card')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='withdrawalCommissionToDriverCard'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
      </StackComponent>

      <UIFormLabel>{t('uklon_trips_page.withdrawal_from_driver_balance')}</UIFormLabel>
      <TextField
        value={(
          +walletTransfer +
          +cashPayment +
          +withdrawalToDriverCard +
          +withdrawalCommissionToDriverCard
        ).toFixed(2)}
        disabled
      />

      <StackComponent alignItems='flex-end' spacing={1.5}>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.driver_wallet_transfer')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='driverWalletTransfer'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
        <FormControl fullWidth>
          <UIFormLabel>{t('uklon_trips_page.driver_wallet_replenishment')}</UIFormLabel>
          <FormTextInput
            control={control}
            name='driverWalletReplenishment'
            regExp={/^(\d+)?(\.\d{0,2})?$/}
            rules={{
              validate: (val) => (val ? /^(?:\d+|\d*\.\d+)$/.test(val as string) : true),
            }}
          />
        </FormControl>
      </StackComponent>

      <UIFormLabel>{t('uklon_trips_page.driver_balance_replenishment')}</UIFormLabel>
      <TextField value={(+driverWalletTransfer + +driverWalletReplenishment).toFixed(2)} disabled />
    </Stack>
  );
};
