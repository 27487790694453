import { forwardRef, useImperativeHandle, PropsWithChildren, useState } from 'react';
import ModalTemplate from '@/shared/modal-template';
import { Box, Dialog, FormControl, Grid, Typography } from '@mui/material';
import { BaseModalRef } from '@/app/helpers';
import { useOpen } from '@/app/hooks';
import { useTranslation } from 'react-i18next';
import { UIFormLabel } from '@/shared/ui/ui-form-label';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { useForm } from 'react-hook-form';
import {
  useAddTaxiCompanyDriverByIdMutation,
  useLazyGetTaxiCompanyDriverDataByIdQuery,
} from '@/app/api';
import notify from '@/shared/toaster/lib/notify';
import _ from 'lodash';
import { YandexDriverById } from '@/app/api/model';

interface FormValues {
  driverId: string;
}

const DriverInfoRow = ({ label, children }: PropsWithChildren<{ label: string }>) => (
  <>
    <Grid item xs={6}>
      <Typography sx={{ fontSize: '14px', color: '#707D9F' }}>{label}</Typography>
    </Grid>
    <Grid item xs={6} sx={{ textAlign: 'right' }}>
      <Typography variant='body2' fontWeight={600}>
        {children}
      </Typography>
    </Grid>
  </>
);

export const ImportYandexDriverByIdModal = forwardRef<BaseModalRef>((_props, ref) => {
  const { t } = useTranslation();
  const { open, setOpen } = useOpen();
  const { control, watch, reset } = useForm<FormValues>();

  const [driverData, setDriverData] = useState<YandexDriverById | null>(null);
  const driverId = watch('driverId');

  const [triggerGetDriverData] = useLazyGetTaxiCompanyDriverDataByIdQuery();
  const [addDriver, { isLoading }] = useAddTaxiCompanyDriverByIdMutation();

  useImperativeHandle(ref, () => ({ open: onOpen, close: onClose }), []);

  const onOpen = () => setOpen(true);

  const onClose = () => {
    setDriverData(null);
    reset();
    setOpen(false);
  };

  const handleAddDriver = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!!driverData) {
      try {
        const response = await addDriver({ driverId }).unwrap();
        if (response.success) {
          notify(t('toast.success'), 'success');
          onClose();
        } else {
          notify(t('toast.error'), 'error');
        }
      } catch (error) {
        notify(t('toast.error'), 'error');
      }
    }
  };

  const onChangeDriverId = async (driverId: string | undefined) => {
    if (!driverId) {
      setDriverData(null);
      return;
    }

    if (driverId.length > 31) {
      try {
        const response = await triggerGetDriverData({ driverId }).unwrap();
        setDriverData(response.data || null);
      } catch (error) {
        notify(t('toast.error'), 'error');
        setDriverData(null);
      }
    }
  };

  const debounceOnChange = _.debounce(onChangeDriverId, 1500);

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { maxWidth: '560px', width: '100%' } }}>
      <ModalTemplate
        submitBtnText={t('add_register')}
        title={t('import_driver_from_yandex_go_by_id')}
        onClose={onClose}
        onSubmit={handleAddDriver}
        disabled={isLoading}
      >
        <FormControl required fullWidth>
          <UIFormLabel required>{t('add_driver_by_id')}:</UIFormLabel>
          <FormTextInput
            control={control}
            name='driverId'
            placeholder={t('not_entered')}
            rules={{ required: t('field_required') }}
            onChange={(event) => debounceOnChange(event.target.value)}
          />
        </FormControl>

        {driverData && (
          <Box sx={{ bgcolor: '#F4F5F7', borderRadius: 2, p: 2, mt: 2 }}>
            <Grid container spacing={1} sx={{ alignItems: 'center' }}>
              <DriverInfoRow label={t('fio')}>{driverData.name}</DriverInfoRow>
              <DriverInfoRow label={t('pinfl')}>{driverData.pinfl}</DriverInfoRow>
              <DriverInfoRow label={t('phone_number')}>{driverData.phone}</DriverInfoRow>
            </Grid>
          </Box>
        )}
      </ModalTemplate>
    </Dialog>
  );
});
