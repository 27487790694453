import { FC, useMemo, useState } from 'react';
import { useAppSelector, useFilters } from '@/app/hooks';
import { useModalRef } from '@/app/hooks/use-modal-ref';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import { FormTextInput } from '@/shared/inputs/form-text-input';
import { PageSizeSelect } from '@/shared/page-size-select';
import { UIButton } from '@/shared/ui/ui-button';
import { UITable } from '@/shared/ui/ui-table';
import { Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerDocsFilterComponent } from './yandex-docs-filter';
import { useNewGetInvoicesQuery } from '@/app/api';
import { UIChip } from '@/shared/ui/ui-chip';
import { SignDocsModal } from '@/features/sign-docs-modal';
import { Link } from 'react-router-dom';
import PermissionCheckHOC from '../../../shared/permission-hoc';
import { checkIfHasPermission } from '../../../app/helpers';
import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import { DocumentsTabComponentProps, FilterParams, YandexDocsFormValues } from '../types';
import { DocumentActionType, SignDocsModalRef } from '@/features/sign-docs-modal/types';
import { UIChipColor } from '@/shared/ui/ui-chip/types';
import { YandexDocStatus, YandexDoc } from '@/app/api/model';

const initialFilterParams: FilterParams = {
  page: 1,
  pageSize: 10,
  dateFrom: '',
  dateTo: '',
  type: undefined,
  status: undefined,
  search: '',
};

export const DocumentsTabComponent: FC<DocumentsTabComponentProps> = ({
  statusesData,
  typesData,
}) => {
  const { t } = useTranslation();
  const { roles } = useAppSelector((state) => state.auth);
  const form = useForm<YandexDocsFormValues>({
    defaultValues: {
      ...initialFilterParams,
      type: { id: undefined, name: '' },
      status: { id: undefined, name: '' },
    },
  });
  const [selected, setSelected] = useState<number[]>([]);

  const { filterParams, onChangeFilterParams } = useFilters<FilterParams>({
    initialFilterParams,
  });

  const yandexDocsFilterRef = useModalRef();
  const signDocsModalRef = useModalRef<SignDocsModalRef>();

  const { data: documentsData, isFetching, refetch } = useNewGetInvoicesQuery(filterParams);

  const canNotBeSigned = useMemo(
    () =>
      selected
        .map((idx) => (documentsData?.data ?? [])[idx])
        .every((el) => el.status === 'waitContragentSign'),
    [selected]
  );

  const onSubmitHandler = (data: YandexDocsFormValues) => {
    onChangeFilterParams({
      ...data,
      type: typeof data?.type === 'object' ? data.type.id : data?.type,
      status: typeof data?.status === 'object' ? data.status.id : data?.status,
      dateFrom:
        typeof data.dateFrom === 'string'
          ? data.dateFrom
          : data.dateFrom
            ? dayjs(data.dateFrom).format('DD.MM.YYYY')
            : '',
      dateTo:
        typeof data.dateTo === 'string'
          ? data.dateTo
          : data.dateTo
            ? dayjs(data.dateTo).format('DD.MM.YYYY')
            : '',
      page: 1,
    });
  };

  const onSignDocs = (action: DocumentActionType) => {
    const docs: YandexDoc[] = selected
      .map((idx) => (documentsData?.data ?? [])[idx])
      .map((doc) => ({
        date: doc.date,
        id: doc.id,
        index: doc.index,
        name: doc.basedDocument.name,
        status: doc.status,
        unique: doc.unique,
        amount: doc.amount,
        didoxId: doc.didoxId,
      }));
    signDocsModalRef.current?.init(docs ?? [], action);
  };

  const getChipType = (
    status: YandexDocStatus,
    label: string
  ): {
    label: string;
    color: UIChipColor;
  } => {
    switch (status) {
      case 'canceled':
        return {
          label: label,
          color: 'error',
        };
      case 'draft':
        return {
          label: label,
          color: 'purple',
        };
      case 'rejected':
        return {
          label: label,
          color: 'error',
        };
      case 'signed':
        return {
          label: label,
          color: 'success',
        };
      case 'waitContragentSign':
        return {
          label: label,
          color: 'primary',
        };
      case 'draftDeleted':
        return {
          label: label,
          color: 'error',
        };
      default:
        return {
          label: label,
          color: 'purple',
        };
    }
  };

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18742']), [roles]);

  return (
    <Stack spacing={2}>
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' spacing={1.5}>
          <PermissionCheckHOC
            WrappedComponent={UIButton}
            props={{
              onClick: () => onSignDocs('sign'),
              disabled: !selected.length || canNotBeSigned || !hasPermission,
              children: t('to_sign'),
            }}
          />
          <PermissionCheckHOC
            WrappedComponent={UIButton}
            props={{
              onClick: () => onSignDocs('delete'),
              disabled: !selected.length || !hasPermission,
              children: t('to_delete'),
            }}
          />
        </Stack>

        <Stack
          component='form'
          direction='row'
          spacing={1.5}
          onSubmit={form.handleSubmit(onSubmitHandler)}
        >
          <FormTextInput control={form.control} name='search' label={t('search')} />
          <UIButton type='submit'>{t('to_find')}</UIButton>
          <PageSizeSelect
            value={filterParams.pageSize}
            onChange={({ target }) =>
              onChangeFilterParams({ pageSize: Number(target.value), page: 1 })
            }
          />
          <UIButton
            color='secondary'
            variant='outlined'
            onClick={() => {
              yandexDocsFilterRef.current?.open();
            }}
          >
            {t('filter')}
          </UIButton>
        </Stack>
      </Stack>

      <UITable
        data={documentsData?.data ?? []}
        EmptyPlaceholder={<EmptyPlaceholder />}
        isLoading={isFetching}
        checkbox={{ selected, setSelected }}
        columns={[
          {
            label: '№',
            key: 'index',
            nowrap: true,
          },
          { label: t('date'), key: 'date' },
          { label: t('number'), key: 'number' },
          {
            label: t('type'),
            render: (row) => {
              return (
                <Link to={`/customer/documents${row.didoxId ? `/${row.didoxId}/documents` : ''}`}>
                  {row.type}
                </Link>
              );
            },
          },
          { label: t('yandex_docs_page.doc_created'), key: 'date' },
          { label: t('sum'), key: 'amount' },
          {
            label: t('status'),
            render: ({ status }) => (
              <UIChip {...getChipType(status, t(`employee_assigment_status.${status}`))} />
            ),
          },
        ]}
        PaginationProps={{
          count: documentsData?.totalPages ?? 0,
          page: filterParams.page,
          onChange: (_e, page) => onChangeFilterParams({ page }),
          disabled: typeof documentsData?.totalPages === 'undefined',
        }}
      />
      <CustomerDocsFilterComponent
        ref={yandexDocsFilterRef}
        form={form}
        onChangeFilterParams={onChangeFilterParams}
        initialFilterParams={initialFilterParams}
        documentStatusesData={statusesData}
        closingDocumentTypesData={typesData}
      />
      <SignDocsModal ref={signDocsModalRef} callback={refetch} />
    </Stack>
  );
};
