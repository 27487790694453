import { UIButton } from '@/shared/ui/ui-button';
import { ButtonProps } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = ButtonProps & {
  status: number | string;
};

export const DidoxDocStatus: FC<Props> = ({ status, ...rest }) => {
  const { t } = useTranslation();
  const getStatusProps = () => {
    let text = status;
    let color: ButtonProps['color'] = 'inherit';

    switch (status) {
      case 50:
        text = t('didox_doc_statuses.gnk_rejected');
        color = 'error';
        break;
      case 40:
        text = t('didox_doc_statuses.not_valid');
        break;
      case 6:
        text = t('didox_doc_statuses.waiting_agent_signature');
        color = 'warning';
        break;
      case 5:
        text = t('didox_doc_statuses.deleted');
        color = 'error';
        break;
      case 4:
        text = t('didox_doc_statuses.rejected');
        color = 'error';
        break;
      case 3:
      case 8:
        text = t('didox_doc_statuses.signed');
        color = 'success';
        break;
      case 2:
      case 9:
        text = t('didox_doc_statuses.waiting_your_signature');
        color = 'warning';
        break;
      case 1:
        text = t('didox_doc_statuses.waiting_partner_signature');
        color = 'warning';
        break;
      case 0:
        text = t('didox_doc_statuses.draft');
        break;
      case 66:
        text = t('didox_doc_statuses.request_accepted');
        color = 'warning';
        break;
      case 67:
        text = t('didox_doc_statuses.request_accepted');
        color = 'warning';
        break;
      case 60:
        text = t('didox_doc_statuses.agent_invoice');
        color = 'primary';
        break;
      case 55:
        text = t('didox_doc_statuses.draftDeleted');
        color = 'error';
        break;
      default:
        break;
    }

    return {
      text,
      color,
    };
  };

  const { text, color } = getStatusProps();

  return (
    <UIButton
      disableElevation
      color={color}
      {...rest}
      sx={{
        cursor: 'default',
        ...rest.sx,
      }}
    >
      {text}
    </UIButton>
  );
};
