import { FC, useCallback } from 'react';
import { UISection } from '@/shared/ui/ui-section';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { numericFormatter } from 'react-number-format';
import { UILoader } from '@/shared/ui/ui-loader';
import { useCheckBalanceWithPeriodsQuery } from '@/app/api';
import { useAppSelector } from '@/app/hooks';

import WalletIcon from '@/app/assets/icons/wallet.svg?react';
import CircleArrow from '@/app/assets/icons/circle-arrow-down.svg?react';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';

interface BalanceWidgetsProps {
  from: string | undefined;
  to: string | undefined;
  typeFinance: number | null | undefined;
}

export const BalanceWidgets: FC<BalanceWidgetsProps> = ({ from, to, typeFinance }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { company } = useAppSelector((state) => state.auth);

  const {
    data: balanceWithPeriods,
    isLoading: isBalanceWithPeriodsLoading,
    isFetching: isBalanceWithPeriodsFetching,
  } = useCheckBalanceWithPeriodsQuery(
    { tin: company?.tin as string, from, to },
    { skip: !company?.tin }
  );

  const getDate = useCallback(() => {
    return t('from_to', {
      from: from ?? 'xx.xx.xxxx',
      to: to ?? 'xx.xx.xxxx',
    });
  }, [from, to]);

  return (
    <Stack direction='row' spacing={2} useFlexGap flexWrap='wrap'>
      <Box
        component={UISection}
        flex={1}
        sx={{
          px: 2,
          py: 1.5,
        }}
        flexBasis={isMobile ? '100%' : 'auto'}
      >
        <Stack direction='row' spacing={1}>
          <WalletIcon />
          <Typography variant='body2' color={Colors.ACCENT}>
            {t('balance')}:
          </Typography>
        </Stack>

        {/* {isCheckBalanceLoading || isCheckBalanceFetching ? ( */}
        {isBalanceWithPeriodsLoading || isBalanceWithPeriodsFetching ? (
          <Box
            sx={{
              maxWidth: 'fit-content',
              mt: 1.5,
            }}
          >
            <UILoader size={20} />
          </Box>
        ) : (
          <Typography variant='h6' mt={0.5}>
            {numericFormatter(
              String(((balanceWithPeriods?.availableBalance ?? 0) / 100).toFixed(2)),
              {
                thousandSeparator: ' ',
              }
            )}{' '}
            <Box component='span' color={Colors.BGRAY}>
              UZS
            </Box>
          </Typography>
        )}
      </Box>

      <Box
        component={UISection}
        flex={1}
        sx={{
          px: 2,
          py: 1.5,
        }}
        flexBasis={isMobile ? '100%' : 'auto'}
      >
        <Stack direction='row' spacing={1}>
          <CircleArrow />
          <Typography
            variant='body2'
            color={Colors.SUCCESS}
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {from || to ? getDate() : t('income_for_7_days')}:
          </Typography>
        </Stack>

        {isBalanceWithPeriodsLoading || isBalanceWithPeriodsFetching ? (
          <Box
            sx={{
              maxWidth: 'fit-content',
              mt: 1.5,
            }}
          >
            <UILoader size={20} />
          </Box>
        ) : (
          <Typography variant='h6' mt={0.5}>
            {!typeFinance || typeFinance === 1
              ? numericFormatter(
                  String(
                    (
                      ((from || to
                        ? balanceWithPeriods?.incomeTransactionsByInterval
                        : balanceWithPeriods?.incomeTransactionsFor7days) ?? 0) / 100
                    ).toFixed(2)
                  ),
                  {
                    thousandSeparator: ' ',
                  }
                )
              : 0}{' '}
            <Box component='span' color={Colors.BGRAY}>
              UZS
            </Box>
          </Typography>
        )}
      </Box>

      <Box
        component={UISection}
        flex={1}
        sx={{
          px: 2,
          py: 1.5,
        }}
        flexBasis={isMobile ? '100%' : 'auto'}
      >
        <Stack direction='row' spacing={1}>
          <Box
            component={CircleArrow}
            sx={{
              transform: 'rotateX(180deg)',
              '& path': {
                fill: Colors.ERROR,
              },
            }}
          />
          <Typography variant='body2' color={Colors.ERROR}>
            {from || to ? getDate() : t('consumption_for_7_days')}:
          </Typography>
        </Stack>

        {isBalanceWithPeriodsLoading || isBalanceWithPeriodsFetching ? (
          <Box
            sx={{
              maxWidth: 'fit-content',
              mt: 1.5,
            }}
          >
            <UILoader size={20} />
          </Box>
        ) : (
          <Typography variant='h6' mt={0.5}>
            {!typeFinance || typeFinance === 2
              ? numericFormatter(
                  String(
                    (
                      ((from || to
                        ? balanceWithPeriods?.expenseTransactionsByInterval
                        : balanceWithPeriods?.expenseTransactionsFor7days) ?? 0) / 100
                    ).toFixed(2)
                  ),
                  {
                    thousandSeparator: ' ',
                  }
                )
              : 0}{' '}
            <Box component='span' color={Colors.BGRAY}>
              UZS
            </Box>
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
