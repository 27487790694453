import React, { createContext, useContext, ReactNode, useMemo, PropsWithChildren } from 'react';
import { Box, StackProps } from '@mui/material';

import { ReportsContextType, ReportsPageFormValues } from '../types';
import { UseFormReturn } from 'react-hook-form';

const initialValues: ReportsContextType = {
  StackComponent: (props: PropsWithChildren<StackProps>) => <Box>{props.children}</Box>,
  form: {} as UseFormReturn<ReportsPageFormValues>,
  yandexReportData: undefined,
  uklonReportData: undefined,
  yandexReportRows: [],
  uklonReportRows: [],
  onClickRemoveYandexOrUklonRow: () => {},
  onClickUpdateYandexRow: () => {},
  onClickUpdateUklonRow: () => {},
  onMountGetReport: async () => {},
  onClickImportRowsFromAggregator: async () => {},
  closingDocumentOptions: undefined,
  unique: undefined,
  isCreating: false,
  isRewardReport: false,
  isTravelReport: false,
  isCorporateReport: false,
  isDeclineReportDisabled: false,
  isReportEditLoading: false,
  isReportPostLoading: false,
  isYandex: false,
  isUklon: false,
  isPosted: false,
  isImportFromAggregatorLoading: false,
  title: undefined,
  onClickPostReport: () => {},
  arrOfClosingDocs: [],
  handleRecreateClosingDocument: async () => {},
  isRecreateClosingDocumentLoading: false,
  isImportInProgress: false,
  importedCounts: {
    totalCount: 0,
    activeCount: 0,
    nonActiveCount: 0,
  },
};

const ReportsContext = createContext<ReportsContextType>(initialValues);

export const useReportsContext = () => {
  const context = useContext(ReportsContext);
  return context;
};

interface ReportsProviderProps {
  value: ReportsContextType;
  children: ReactNode;
}

export const ReportsProvider: React.FC<ReportsProviderProps> = ({ value, children }) => {
  const contextValue = useMemo(() => value, [value]);
  return <ReportsContext.Provider value={contextValue}>{children}</ReportsContext.Provider>;
};
