import { FC, useMemo } from 'react';
import { UITable } from '@/shared/ui/ui-table';
import { Box, Stack, Typography } from '@mui/material';
import {
  DefaultResponseWithPagination,
  PaginationParams,
  checkIfHasPermission,
  getSumFromTiins,
} from '@/app/helpers';
import { EmptyPlaceholder } from '@/shared/empty-placeholder';
import { numericFormatter } from 'react-number-format';
import { FormTextInput } from '../../shared/inputs/form-text-input';
import { UIButton } from '../../shared/ui/ui-button';
import { FormDatePicker } from '../../shared/inputs/form-date-picker';
import { BalanceFormValues } from '../../pages/balance-page';
import PermissionCheckHOC from '../../shared/permission-hoc';
import { useLazyDownloadTransactionsInExcelQuery } from '../../app/api';
import { useAppSelector } from '../../app/hooks';
import dayjs, { Dayjs } from 'dayjs';

import IncomeIcon from '@/app/assets/icons/add-circle.svg?react';
import ExpenseIcon from '@/app/assets/icons/minus-cirlce.svg?react';

import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';
import { OnChangeFilterParams } from '../../app/hooks/use-filters/types';
import { GetTransactionsRequestBody, Transaction } from '../../app/api/model';
import { UseFormReturn } from 'react-hook-form';

interface BalanceInfoTableProps {
  isTiins: boolean;
  data: DefaultResponseWithPagination<Transaction[]> | undefined;
  isLoading: boolean;
  filterParams: PaginationParams<GetTransactionsRequestBody>;
  onChangeFilterParams: OnChangeFilterParams<PaginationParams<BalanceFormValues>>;
  form: UseFormReturn<BalanceFormValues, any, undefined>;
}

export const BalanceInfoTable: FC<BalanceInfoTableProps> = ({
  isTiins,
  data,
  isLoading,
  filterParams,
  onChangeFilterParams,
  form,
}) => {
  const { t } = useTranslation();
  const { company, roles } = useAppSelector((state) => state.auth);

  const { handleSubmit, control, reset } = form;

  const [getTransactionsInExcel, { isFetching: isTransactionsLoading }] =
    useLazyDownloadTransactionsInExcelQuery();

  const onSubmit = (data: BalanceFormValues) => {
    onChangeFilterParams({
      ...filterParams,
      ...data,
      from: data.from ? dayjs(data.from).format('DD.MM.YYYY') : data.from,
      to: data.to ? dayjs(data.to).format('DD.MM.YYYY') : data.to,
    });
  };

  const onClickGetTransactions = async () => {
    await getTransactionsInExcel({
      TIN: company?.tin,
      from: filterParams.from,
      to: filterParams.to,
      typeFinance: filterParams.typeFinance,
    }).unwrap();
  };

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18712']), [roles]);

  return (
    <Stack spacing={2}>
      <Stack
        component='form'
        onSubmit={handleSubmit(onSubmit)}
        direction='row'
        justifyContent='space-between'
        flexWrap='wrap'
        gap={2}
      >
        <Stack direction='row' gap={2} flexWrap='wrap'>
          <Stack direction='row' spacing={1}>
            <FormTextInput control={control} name='search' label={t('search')} />
            <UIButton type='submit' disabled={isLoading}>
              {t('to_find')}
            </UIButton>
          </Stack>

          <FormDatePicker
            control={control}
            name='from'
            label={t('from') + ':'}
            onChange={(value: Dayjs | null) => {
              if (value && value.isValid() && value.year().toString().length >= 4) {
                onChangeFilterParams({
                  from: value ? dayjs(value).format('DD.MM.YYYY') : '',
                });
              }
            }}
          />
          <FormDatePicker
            control={control}
            name='to'
            label={t('to') + ':'}
            onChange={(value: Dayjs | null) => {
              if (value && value.isValid() && value.year().toString().length >= 4) {
                onChangeFilterParams({
                  to: value ? dayjs(value).format('DD.MM.YYYY') : '',
                });
              }
            }}
          />

          <UIButton type='submit' color='inherit' onClick={() => reset()} disabled={isLoading}>
            {t('reset')}
          </UIButton>
        </Stack>
        <PermissionCheckHOC
          WrappedComponent={UIButton}
          props={{
            onClick: onClickGetTransactions,
            loading: isTransactionsLoading,
            disabled: !data?.data?.length || !hasPermission,
            children: t('excel_download'),
          }}
        />
      </Stack>

      <UITable
        isLoading={isLoading}
        EmptyPlaceholder={<EmptyPlaceholder subtitle={t('no_transactions')} />}
        data={data?.data ?? []}
        columns={[
          {
            label: '№',
            key: 'index',
            nowrap: true,
          },
          {
            label: t('date'),
            key: 'createdAt',
          },
          {
            label: t('correspondent'),
            render: ({ recipient, sender, action }) => {
              let name = recipient?.recipientName || '';
              let tinOrPinfl = '';

              if (action === 'income') {
                name = sender?.senderName;

                if (sender?.senderTIN) {
                  tinOrPinfl = sender?.senderTIN;
                } else if (sender?.senderPINFL) {
                  tinOrPinfl = sender?.senderPINFL;
                }
              } else {
                if (recipient?.recipientTIN) {
                  tinOrPinfl = recipient?.recipientTIN;
                } else if (recipient?.recipientPINFL) {
                  tinOrPinfl = recipient?.recipientPINFL;
                }
              }

              return (
                <Typography variant='body2'>
                  {name}{' '}
                  <Box component='span' color={Colors.BGRAY}>
                    {tinOrPinfl}
                  </Box>
                </Typography>
              );
            },
          },
          {
            label: t('comment'),
            key: 'goodsName',
          },
          {
            label: `${t('sum')}, UZS`,
            // align: 'left',
            // headerAlign: 'left',
            render: ({ amount, action }) => (
              <Stack direction='row' alignItems='center' spacing={0.5} justifyContent='flex-start'>
                {(() => {
                  switch (action) {
                    case 'income':
                      return <Box component={IncomeIcon} />;
                    case 'expense':
                      return <Box component={ExpenseIcon} />;
                    default:
                      return null;
                  }
                })()}
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '1.6rem',
                  }}
                >
                  {numericFormatter(String(getSumFromTiins(amount, isTiins).toFixed(2)), {
                    thousandSeparator: ' ',
                  })}
                </Typography>
              </Stack>
            ),
          },
        ]}
        PaginationProps={{
          page: filterParams.page,
          count: data?.totalPages,
          onChange: (_, page) => onChangeFilterParams({ page }),
        }}
      />
    </Stack>
  );
};
