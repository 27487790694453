import { useMemo, useRef, useState } from 'react';
import { didoxApi } from '@/app/api/didox-api';
import { Layout } from '@/shared/layout';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { UIButton } from '@/shared/ui/ui-button';
import { UISection } from '@/shared/ui/ui-section';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PermissionCheckHOC from '../../shared/permission-hoc';
import { checkIfHasPermission } from '@/app/helpers';
import { useAppSelector } from '../../app/hooks';
import { DidoxDocStatus } from './ui/didoc-doc-status';
import {
  rejectDidoxDoc,
  signOutgoingDoc,
  deleteDraft,
  signDidoxDoc,
} from '@/app/helpers/sign-didox-doc';
import { CommentModal, CommentModalRef } from './ui/comment-modal';
import { blobToBase64 } from '@/app/helpers';
import notify from '@/shared/toaster/lib/notify';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

import { DocumentDownloadIcon, PrinterIcon } from '@/shared/icons';

import i18n from '@/app/i18n';
import { useTranslation } from 'react-i18next';
import { Colors } from '@/app/constants/colors';

export const SingleDocumentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { docId = '', from = '' } = useParams<{ docId: string; from: 'report' | 'documents' }>();
  const { roles } = useAppSelector((state) => state.auth);

  const {
    data: html,
    isFetching: isHtmlLoading,
    refetch: refetchHtml,
  } = didoxApi.useGetDocHtmlQuery(docId, {
    skip: !docId,
  });
  const {
    data: docJson,
    isFetching: isDocLoading,
    refetch: refetchDoc,
  } = didoxApi.useGetDocQuery(docId, {
    skip: !docId,
  });

  const docType = useMemo(() => docJson?.data.document.doctype ?? '', [docJson]);

  const { data: docAttachment, isFetching: isAttachmentLoading } =
    didoxApi.useGetDocumentAttachmentQuery(docId, {
      skip: !docId || docType !== '000',
    });

  const documentTitle = useMemo(() => {
    switch (docType) {
      case '000':
        return t('commissioner_report');
      case '002':
        return t('invoice');
      default:
        return t('invoice');
    }
  }, [docType]);

  const documentNameAndDate = useMemo(() => {
    let name = '';
    let date = '';

    if (docJson) {
      switch (docType) {
        case '000':
          name = docJson.data.json.document.documentname ?? '';
          date =
            dayjs(docJson.data.json.document.documentdate, 'DD.MM.YYYY').format('DD.MM.YYYY') ?? '';
          break;
        case '002':
          name = docJson.data.json?.facturadoc?.facturano ?? '';
          date = docJson.data.json?.facturadoc?.facturadate ?? '';
          break;
        default:
          name = '';
          date = '';
          break;
      }
    }

    return t('document_title', {
      name,
      date,
    });
  }, [docJson]);

  const [isSignLoading, setIsSignLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfPages, setPdfPages] = useState<number[]>([]);

  const docHtmlRef = useRef<HTMLDivElement>(null);
  const commentModalRef = useRef<CommentModalRef>(null);

  const handleOnSign = async () => {
    try {
      setIsSignLoading(true);
      await signDidoxDoc(docId);

      await Promise.all([refetchDoc().unwrap(), refetchHtml().unwrap()]);
      notify(t('invoice_signed_successfully'), 'success');
    } catch (err) {
      const error = err?.data?.data?.message ?? '';
      notify(error, 'error', {
        autoClose: false,
      });
    } finally {
      setIsSignLoading(false);
    }
  };

  const handleOnAttachSign = async () => {
    try {
      setIsSignLoading(true);
      await signOutgoingDoc(docId);

      await Promise.all([refetchDoc().unwrap(), refetchHtml().unwrap()]);
      notify(t('invoice_signed_successfully'), 'success');
    } catch (err) {
      const error = err?.data?.data?.message ?? '';
      notify(error, 'error', {
        autoClose: false,
      });
    } finally {
      setIsSignLoading(false);
    }
  };

  const handleOnReject = async () => {
    const comment = await commentModalRef.current?.init({ title: t('reject_title') });
    if (!comment) return;
    try {
      setIsRejectLoading(true);
      await rejectDidoxDoc(docId, comment);

      await Promise.all([refetchDoc().unwrap(), refetchHtml().unwrap()]);
      notify(t('invoice_rejected_successfully'), 'success');
    } catch (err) {
      const error = err?.data?.data?.message ?? '';
      notify(error, 'error', {
        autoClose: false,
      });
    } finally {
      setIsRejectLoading(false);
    }
  };

  const handleOnDeleteDraft = async () => {
    try {
      setIsRejectLoading(true);
      await deleteDraft(docId);

      await Promise.all([refetchDoc().unwrap(), refetchHtml().unwrap()]);
      notify(t('invoice_deleted_successfully'), 'success');
    } catch (err) {
      const error = err?.data?.data?.message ?? '';
      notify(error, 'error', {
        autoClose: false,
      });
    } finally {
      setIsRejectLoading(false);
    }
  };

  const handleOnHtmlPrint = () => {
    if (docHtmlRef.current) {
      const printWindow = window.open('', 'PRINT', 'height=600,width=800');
      printWindow?.document.write(docHtmlRef.current.innerHTML);
      printWindow?.print();
    }
  };

  const handleOnDownloadPdf = async () => {
    try {
      setPdfLoading(true);
      const blob = await fetch(`${import.meta.env.VITE_DIDOX_API_BASE_URL}documents/${docId}/pdf`, {
        headers: {
          'user-key': Cookies.get('user-key') ?? '',
          'Accept-Language': i18n.language,
        },
      }).then((res) => {
        if (!res.ok) return Promise.reject(res);
        return res.blob();
      });
      const fileName = '';
      const base64 = await blobToBase64(blob);
      const link = document.createElement('a');
      link.href = base64 as string;

      link.download = `${getFileName(fileName)}.pdf`;
      link.click();
    } catch (err) {
      notify(err?.data?.data?.message ?? '', 'error');
    } finally {
      setPdfLoading(false);
    }
  };

  const getFileName = (fileName: string): string => {
    switch (docType) {
      case '000':
        fileName = docJson?.data.json.document.documentno ?? '';
        break;
      case '002':
        fileName = docJson?.data.json?.facturadoc?.facturano ?? '';
        break;
    }

    return fileName;
  };

  const onLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPdfPages([...Array(numPages)]);
  };

  const handleOnClickBack = () => {
    if (from === 'documents') {
      navigate('/customer/documents?activeTab=1');
    } else {
      navigate(-1);
    }
  };

  const hasPermission = useMemo(() => checkIfHasPermission(roles, ['18742']), [roles]);

  return (
    <Layout>
      <UISection
        sx={{
          flex: 1,
        }}
      >
        <Stack
          sx={{
            height: '100%',
          }}
        >
          <Stack direction='row' spacing={2} alignItems='center'>
            <UIButton color='inherit' onClick={handleOnClickBack}>
              {t('back')}
            </UIButton>

            {isDocLoading ? (
              <Skeleton variant='text' width={200} height={40} />
            ) : (
              <Typography variant='h5'>
                {documentTitle}{' '}
                <Box component='span' color={Colors.BGRAY}>
                  {documentNameAndDate}
                </Box>
              </Typography>
            )}
          </Stack>

          <Stack direction='row' justifyContent='space-between' alignItems='center' mt={3}>
            <Stack direction='row' spacing={2} alignItems='center'>
              {isDocLoading ? (
                <>
                  <Skeleton variant='rounded' width={100} height={40} />
                  <Skeleton variant='text' width={200} height={40} />
                </>
              ) : (
                <>
                  <DidoxDocStatus
                    sx={{ minWidth: 100 }}
                    status={docJson?.data.document.doc_status ?? ''}
                  />
                  <Typography color={Colors.BGRAY}>{docJson?.data.document.updated}</Typography>
                </>
              )}
            </Stack>

            <Stack direction='row' spacing={1}>
              {docJson?.data.document.doc_status === 0 && (
                <>
                  <PermissionCheckHOC
                    WrappedComponent={UIButton}
                    props={{
                      onClick: handleOnSign,
                      disabled: !hasPermission || isRejectLoading,
                      loading: isSignLoading,
                      children: t('to_sign'),
                    }}
                  />
                  <PermissionCheckHOC
                    WrappedComponent={UIButton}
                    props={{
                      onClick: handleOnDeleteDraft,
                      loading: isRejectLoading,
                      disabled: !hasPermission || isSignLoading,
                      children: t('to_delete'),
                    }}
                  />
                </>
              )}
              {docJson?.data.document.doc_status == 2 && (
                <>
                  <PermissionCheckHOC
                    WrappedComponent={UIButton}
                    props={{
                      onClick: handleOnAttachSign,
                      loading: isSignLoading,
                      disabled: !hasPermission || isRejectLoading,
                      children: t('to_sign'),
                    }}
                  />
                  <PermissionCheckHOC
                    WrappedComponent={UIButton}
                    props={{
                      onClick: handleOnReject,
                      loading: isRejectLoading,
                      disabled: !hasPermission || isSignLoading,
                      children: t('to_reject'),
                    }}
                  />
                </>
              )}
              <UIButton
                onClick={handleOnDownloadPdf}
                loading={pdfLoading}
                disabled={isDocLoading}
                color='inherit'
                startIcon={<DocumentDownloadIcon />}
              >
                {t('download_pdf')}
              </UIButton>
              <UIButton
                onClick={handleOnHtmlPrint}
                disabled={isDocLoading}
                color='inherit'
                startIcon={<PrinterIcon />}
              >
                {t('print')}
              </UIButton>
            </Stack>
          </Stack>

          <Box
            mt={3}
            sx={{
              flex: 1,
            }}
          >
            {isHtmlLoading ? (
              <Skeleton variant='rounded' height='100%' />
            ) : (
              <Box
                ref={docHtmlRef}
                component='div'
                dangerouslySetInnerHTML={{ __html: html || '' }}
              />
            )}
          </Box>

          <Box
            mt={3}
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {isAttachmentLoading ? (
              <Skeleton variant='rounded' height='100%' />
            ) : (
              <Document
                file={docAttachment}
                onLoadSuccess={onLoadSuccess}
                noData={docType === '000' ? t('yandex_docs_page.pdf_error') : ''}
              >
                {pdfPages.map((_i, index) => (
                  <Box
                    key={index}
                    sx={{
                      oferflow: 'hidden',
                      '& @media print': {
                        pageBreakInside: 'avoid',
                      },
                    }}
                  >
                    <Page className={'pdf__canvas'} pageNumber={index + 1} scale={1} />
                  </Box>
                ))}
              </Document>
            )}
          </Box>
        </Stack>
      </UISection>
      <CommentModal ref={commentModalRef} />
    </Layout>
  );
};
