import { SyntheticEvent, useMemo, useRef, useState } from 'react';
import { Layout } from '@/shared/layout';
import { FormControlLabel, Stack } from '@mui/material';
import { UISection } from '@/shared/ui/ui-section';
import { BalanceWidgets } from '@/widgets/balance-widgets';
import UITabs from '@/shared/ui/ui-tabs';
import { UISwitch } from '@/shared/ui/ui-switch';
import { BalanceInfoTable } from '@/features/balance-info-table';
import { UIButton } from '../../shared/ui/ui-button';
import PermissionCheckHOC from '../../shared/permission-hoc';
import { useAppSelector, useFilters } from '../../app/hooks';
import {
  useCheckActiveBalanceNotificationsBotBindingMutation,
  useCreateBalanceNotificationsBotBindingMutation,
  useDeleteBalanceNotificationsBotBindingMutation,
  useGetTransactionsQuery,
} from '../../app/api';
import ConfirmModal from '../../shared/confirm-modal';

import { useTranslation } from 'react-i18next';
import { ConfirmModalRef } from '../../shared/confirm-modal/types';
import notify from '../../shared/toaster/lib/notify';
import { useSearchParams } from 'react-router-dom';
import { GetTransactionsRequestBody } from '../../app/api/model';
import { PaginationParams } from '../../app/helpers';
import { useForm } from 'react-hook-form';

export interface BalanceFormValues
  extends Omit<GetTransactionsRequestBody, 'typeFinance' | 'TIN'> {}

const initialFilterParams: PaginationParams<GetTransactionsRequestBody> = {
  page: 1,
  pageSize: 20,
  search: '',
  from: '',
  to: '',
};

export const BalancaPage = () => {
  const { t } = useTranslation();
  const { roles, company, isPassLog } = useAppSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTiins, setIsTiins] = useState(false);

  const confirmModalRef = useRef<ConfirmModalRef>(null);

  const currentTin = useMemo(
    () => company?.tin ?? company?.companyTin ?? company?.pinfl,
    [company]
  );

  const { filterParams, onChangeFilterParams } = useFilters<
    PaginationParams<GetTransactionsRequestBody>
  >({
    initialFilterParams: {
      ...initialFilterParams,
      TIN: currentTin,
      typeFinance: Number(searchParams.get('activeTab')),
    },
  });

  const form = useForm<BalanceFormValues>({
    defaultValues: {
      search: '',
      from: '',
      to: '',
    },
  });

  const [checkWhetherBinded, { isLoading: isCheckWhetherBindedLoading }] =
    useCheckActiveBalanceNotificationsBotBindingMutation();
  const [createBinding, { isLoading: isCreateBindingLoading }] =
    useCreateBalanceNotificationsBotBindingMutation();
  const [deleteBinding, { isLoading: isDeleteBindingLoading }] =
    useDeleteBalanceNotificationsBotBindingMutation();
  const { data, isLoading, isFetching } = useGetTransactionsQuery(filterParams, { skip: !company });

  const checkWhetherBindedHandler = async () => {
    const { bindingDate, hasActiveBinding } = await checkWhetherBinded({}).unwrap();
    if (hasActiveBinding) {
      try {
        const answer = await confirmModalRef.current?.init({
          title: t('get_telegram_notifications'),
          text: t('notifications_are_already_setup', {
            date: bindingDate,
          }),
          btnText: t('bind_another_account'),
          cancelBtnText: t('cancel_notification_binding'),
        });
        if (answer) {
          const { link } = await createBinding({}).unwrap();
          handleLinkClick(link);
        }
      } catch (error) {
        if (!error) {
          await deleteBinding({}).unwrap();
          notify(t('notifications_are_cancelled'), 'success');
        }
      }
    } else {
      const { link } = await createBinding({}).unwrap();
      handleLinkClick(link);
    }
  };

  const handleLinkClick = (link: string) => {
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    notify(t('notifications_are_setup'), 'success');
  };

  const handleTabChange = (_event: SyntheticEvent<Element, Event>, value: number) => {
    onChangeFilterParams({
      typeFinance: value,
    });
    setSearchParams({ activeTab: value.toString() });
  };

  return (
    <Layout>
      <Stack
        spacing={2}
        sx={{
          height: '100%',
        }}
      >
        <BalanceWidgets
          from={filterParams.from}
          to={filterParams.to}
          typeFinance={filterParams.typeFinance}
        />
        <UISection
          sx={{
            height: '100%',
            pt: 0,
          }}
        >
          <UITabs
            value={filterParams.typeFinance || 0}
            onChange={handleTabChange}
            tabs={[
              {
                label: t('all'),
                component: (
                  <BalanceInfoTable
                    isTiins={isTiins}
                    onChangeFilterParams={onChangeFilterParams}
                    filterParams={filterParams}
                    data={data}
                    isLoading={isLoading || isFetching}
                    form={form}
                  />
                ),
              },
              {
                label: t('income'),
                component: (
                  <BalanceInfoTable
                    isTiins={isTiins}
                    onChangeFilterParams={onChangeFilterParams}
                    filterParams={filterParams}
                    data={data}
                    isLoading={isLoading || isFetching}
                    form={form}
                  />
                ),
              },
              {
                label: t('consumption'),
                component: (
                  <BalanceInfoTable
                    isTiins={isTiins}
                    onChangeFilterParams={onChangeFilterParams}
                    filterParams={filterParams}
                    data={data}
                    isLoading={isLoading || isFetching}
                    form={form}
                  />
                ),
              },
            ]}
            endAdornment={
              <Stack direction='row' spacing={1.5}>
                {!isPassLog && (
                  <PermissionCheckHOC
                    WrappedComponent={UIButton}
                    props={{
                      children: t('get_telegram_notifications'),
                      color: 'primary',
                      size: 'small',
                      disabled: !!roles.length,
                      loading:
                        isCheckWhetherBindedLoading ||
                        isCreateBindingLoading ||
                        isDeleteBindingLoading,
                      onClick: checkWhetherBindedHandler,
                    }}
                  />
                )}
                <FormControlLabel
                  sx={{
                    '& .MuiTypography-root': {
                      ml: 1,
                      fontSize: '1.6rem',
                      fontWeight: 600,
                    },
                  }}
                  control={<UISwitch onChange={(_, checked) => setIsTiins(checked)} />}
                  label={t('sum_in_tiins')}
                />
              </Stack>
            }
          />
        </UISection>
      </Stack>
      <ConfirmModal ref={confirmModalRef} />
    </Layout>
  );
};
